import api from '../../../api/api'

export default {
  async index({ consultores }) {
    return await api.get.getFarmDistribution(consultores)
  },

  async updateConsultantFarm({ consultor, fazenda }) {
    return await api.update.updateConsultantFarm(consultor, fazenda)
  },
}

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          :value="filter"
          avatar
          bordered
          overlap
          offset-x="10"
          dot
          offset-y="10"
        >
          <v-btn plain icon v-bind="attrs" v-on="on">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Opções de Filtro </v-card-title>
        <v-card-text
          ><v-radio-group v-model="farmsFilter" column>
            <v-radio
              label="Todas as fazendas"
              color="green"
              value="all"
            ></v-radio>

            <v-radio
              label="Fazendas ativa"
              color="green"
              value="active"
            ></v-radio>

            <v-radio
              label="Fazendas inativas"
              color="green"
              value="inactive"
            ></v-radio> </v-radio-group
        ></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'FilterDialog',

  data() {
    return {
      dialog: false,

      filter: false,

      farmsFilter: 'all',
    }
  },

  methods: {
    save() {
      this.$emit('filter', this.farmsFilter)
      this.filter = true
      this.dialog = false
    },
  },
}
</script>

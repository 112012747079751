<template>
  <div id="map">
    <gmap-map :center="center" :zoom="zoom" style="width: 100%; height: 700px">
      <gmap-marker
        v-for="(farmDistribution, index) in farmDistributions"
        :key="index"
        :v-if="farmDistribution.point && farmDistribution.localizacao"
        :position="farmDistribution.point"
        :icon="getCustomMark(farmDistribution.identificacao)"
        @click="choseConsultor(farmDistribution, index)"
      >
        <gmap-info-window :opened="showByIndex === index">
          <v-autocomplete
            v-model="form.consultor"
            autocomplete="autocomplete_off_hack_xfr4!k"
            label="Consultor"
            :items="consultores"
            item-text="Nome"
            item-value="id"
            clearable
            required
          />
          <p>
            <strong> Produtor:</strong> {{ farmDistribution.produtor.Nome }}
          </p>
          <p>
            <strong> Fazenda:</strong>
            {{ farmDistribution.pessoajuridica.NomeFantasia }}
          </p>
          <p>
            <strong>Agroindústria: </strong>
            {{
              farmDistribution?.agroindustria_ativa?.agroindustria
                ?.pessoajuridica.NomeFantasia
            }}
          </p>
          <div class="d-flex">
            <strong>Indetificação:</strong>
            <v-avatar
              left
              :color="farmDistribution.identificacao"
              size="16"
              style="border: 1px solid black !important"
              class="ml-2"
            ></v-avatar>
          </div>

          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showByIndex = null">
            Fechar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="loading.save"
            :disabled="loading.save"
            @click="updateFazenda(farmDistribution)"
          >
            Salvar
          </v-btn>
        </gmap-info-window>
      </gmap-marker>

      <gmap-marker
        v-for="(consultor, index) in consultores"
        :key="consultor.id"
        :v-if="consultor.point && consultor.localizacao"
        :position="consultor.point"
        :icon="getCustomHomeMark(consultor.identificacao)"
        @click="choseConsultor(consultor, farmDistributions.length + index)"
      >
        <gmap-info-window
          :opened="showByIndex === farmDistributions.length + index"
        >
          <p style="text-align: center; font-weight: bold"></p>
          <p>Consultor: {{ consultor.Nome }}</p>
          <div class="d-flex">
            <p>Indetificação:</p>
            <v-avatar
              left
              :color="consultor.identificacao"
              size="16"
              style="border: 1px solid black !important"
              class="ml-2"
            ></v-avatar>
          </div>

          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showByIndex = null">
            Fechar
          </v-btn>
        </gmap-info-window>
      </gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import FarmDistributionService from '../../../services/api/farmDistribution'

export default {
  name: 'Maps',

  emits: ['update-fazenda'],

  props: {
    allConsultores: {
      type: Array,
      required: true,
    },

    farmDistributions: {
      type: Array,
      required: true,
    },

    colors: {
      type: Array,
      required: true,
    },

    focus: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      center: { lat: -19.8157, lng: -43.9542 },
      zoom: 4,

      lineOptions: {
        strokeColor: '#FF5733',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      },

      showByIndex: null,

      form: {
        consultor: '',
      },

      loading: {
        save: false,
      },

      consultores: [],
    }
  },

  computed: {
    google: gmapApi,
  },

  watch: {
    focus: {
      handler(item) {
        if (item.localizacao) {
          this.center = item.point
          this.zoom = 15
        } else {
          this.Toast().fire({
            icon: 'warning',
            title: 'Fazenda não possui localização',
          })
        }
      },
      deep: true,
    },
  },

  created() {
    const consultores = this.allConsultores.filter(consultor => {
      let find = this.farmDistributions.find(
        item => item.CodConsultorTecnico === consultor.id,
      )

      if (find) return find
    })

    this.consultores = consultores.map(consultor => {
      const find = this.colors.find(color => color.id === consultor.id)

      if (find) {
        consultor.identificacao = find.color
        return consultor
      }

      consultor.identificacao = this.randomHexColor()

      this.colors.push({
        id: consultor.id,
        color: consultor.identificacao,
      })

      return consultor
    })
  },

  mounted() {},

  methods: {
    getCustomMark(color) {
      return {
        path:
          'M10 0C4.477 0 0 4.477 0 10s10 22 10 22 10-17.523 10-22S15.523 0 10 0zm0 15c-2.481 0-4.5-2.019-4.5-4.5S7.519 6 10 6s4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5z',
        fillColor: color,
        fillOpacity: 1,
        scale: 0.8,
      }
    },

    getCustomHomeMark(color) {
      return {
        path:
          'M360-440h80v-110h80v110h80v-190l-120-80-120 80v190Zm120 254q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z',
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeOpacity: 1.0,
        strokeWeight: 0.9,
        scale: 0.035,
      }
    },

    choseConsultor(item, index) {
      this.showByIndex = index
      this.form.consultor = item.CodConsultorTecnico
    },

    async updateFazenda(item) {
      if (item.CodConsultorTecnico === this.form.consultor) {
        return this.Toast().fire({
          icon: 'warning',
          title: 'Consultor já está vinculado a fazenda',
        })
      }

      try {
        this.loading.save = true
        const response = await FarmDistributionService.updateConsultantFarm({
          fazenda: item.CodFazenda,
          consultor: this.form.consultor,
        })

        if (response.success) {
          this.Toast().fire({
            icon: 'success',
            title: 'Fazenda atualizada com sucesso',
          })

          this.$emit('update-fazenda', {
            CodFazenda: item.CodFazenda,
            CodConsultorTecnico: this.form.consultor,
          })
        } else {
          this.Toast().fire({
            icon: 'error',
            title: 'Erro ao atualizar fazenda',
          })
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: `${error.message}`,
        })
      } finally {
        this.showByIndex = null
        this.loading.save = false
      }
    },
  },
}
</script>

<style>
.distance-info {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.gm-ui-hover-effect {
  display: none !important;
}
</style>

<template>
  <v-card>
    <v-card>
      <v-row align="center" justify="center" class="px-10">
        <v-col cols="4">
          <v-autocomplete
            v-model="form.consultores"
            autocomplete="autocomplete_off_hack_xfr4!k"
            label="Consultores"
            :items="consultores"
            item-text="Nome"
            item-value="id"
            :loading="loading.consultor"
            :disabled="loading.consultor"
            multiple
            clearable
            required
          >
            <template v-slot:prepend>
              <v-icon>mdi-account</v-icon>
            </template>

            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < CHIP_LIMIT" outlined small class="caption">
                <span v-if="index < CHIP_LIMIT">
                  {{ item.Nome }}
                </span>
              </v-chip>
              <span v-if="index === CHIP_LIMIT" class="grey--text caption">
                (+{{ form.consultores.length - CHIP_LIMIT }}
                outros)
              </span>
            </template>

            <template slot="prepend-item">
              <v-btn
                text
                class="green--text"
                elevation="2"
                color="blue"
                block
                small
                @click="selectAll"
              >
                SELECIONAR TODOS
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="1">
          <filter-dialog @filter="farmFilter = $event" />
        </v-col>

        <v-col class="pa-2" cols="2" sm="2" md="2">
          <v-row justify="end" align="center">
            <v-btn
              style="color: white; border-radius: 8px; margin-left: 40%"
              color="green darken-1"
              @click="search()"
            >
              <span style="font-size: 16px; font-weight: 600">Buscar</span>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <base-loading v-if="loading.loading" />
    <v-card v-else>
      <maps
        v-if="farmDistributions.length > 0"
        :farm-distributions="filterFarmDistribution()"
        :all-consultores="consultores"
        :colors="colors"
        :focus="focus"
        @update-fazenda="onUpdateFazenda"
      />
      <crud-list
        :value="filterFarmDistribution()"
        :headers="headers"
        :actions="actions"
        :slots="['item.identificacao', 'item.localizacao']"
      >
        <template v-slot:[`item.identificacao`]="{ item }">
          <v-avatar
            left
            :color="item.identificacao"
            size="16"
            style="border: 1px solid black !important"
          ></v-avatar>
        </template>

        <template v-slot:[`item.localizacao`]="{ item }">
          <v-icon
            v-if="item.localizacao"
            color="green darken-1"
            small
            class="mr-2"
          >
            mdi-check
          </v-icon>
          <v-icon v-else color="red darken-1" small class="mr-2">
            mdi-close
          </v-icon>
        </template>
      </crud-list>
    </v-card>
  </v-card>
</template>

<script>
import ConsultantService from '../../../services/api/consultant'
import FarmDistributionService from '../../../services/api/farmDistribution'
import { GRAPH_COLORS } from '../../../utils/constants'
import FilterDialog from './FilterDialog.vue'
import Maps from './maps.vue'

export default {
  name: 'FarmDistribution',
  components: {
    Maps,
    FilterDialog,
  },
  data() {
    return {
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        { text: 'Consultor', value: 'consultor.Nome', filterable: true },

        {
          text: 'Propriedade',
          value: 'pessoajuridica.NomeFantasia',
          filterable: true,
        },
        {
          text: 'Agroindústria',
          value:
            'agroindustria_ativa.agroindustria.pessoajuridica.NomeFantasia',
          filterable: true,
        },
        { text: 'Produtor', value: 'produtor.Nome', filterable: true },
        { text: 'Localização', value: 'localizacao', filterable: true },
        {
          text: 'Identificação',
          value: 'identificacao',
        },
      ],

      actions: [
        {
          title: 'Ver localização',
          color: 'primary',
          click: item => this.onFocus(item),
          icon: 'mdi-eye',
        },
      ],

      form: {
        consultores: [],
      },

      activeSearch: false,

      consultores: [{ Nome: 'Carregando', id: 0 }],

      farmDistributions: [],
      colors: [],

      loading: {
        loading: false,
        consultor: false,
      },

      dialog: false,
      showByIndex: null,

      MAX_SELECTED_MAKER: 50,

      focus: null,

      externalUserId: null,

      farmFilter: 'all',

      CHIP_LIMIT: 5,
    }
  },

  async created() {
    this.externalLink()
    await this.getConsultores()
  },

  methods: {
    async getConsultores() {
      this.loading.consultor = true
      try {
        const response = await ConsultantService.list()

        const consultores = response.map(consultor => {
          if (
            consultor?.endereco?.Latitude &&
            consultor?.endereco?.Longitude &&
            this.validateCoordinates(
              consultor.endereco.Latitude,
              consultor.endereco.Longitude,
            )
          ) {
            consultor.point = {
              lat: Number(consultor.endereco.Latitude),
              lng: Number(consultor.endereco.Longitude),
            }

            consultor.localizacao = true

            return consultor
          }

          consultor.point = null

          consultor.localizacao = false

          return consultor
        })

        this.consultores = consultores

        if (this.externalUserId) {
          const find = this.consultores.find(
            consultor => consultor.id === this.externalUserId,
          )

          if (find) {
            this.form.consultores = [find.id]
            this.search()
          }
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar consultores',
        })
      } finally {
        this.loading.consultor = false
      }
    },

    async search() {
      try {
        this.loading.loading = true
        const response = await FarmDistributionService.index({
          consultores: this.form.consultores,
        })

        let count = 0

        const result = response.data.map(item => {
          if (
            item.Latitude &&
            item.Longitude &&
            this.validateCoordinates(item.Latitude, item.Longitude)
          ) {
            item.point = {
              lat: Number(item.Latitude),
              lng: Number(item.Longitude),
            }

            item.localizacao = true
            if (count <= this.MAX_SELECTED_MAKER) {
              item.checked = true
              count++
            }
          } else {
            item.point = {
              lat: 0,
              lng: 0,
            }

            item.localizacao = false
          }

          const find = this.colors.find(
            color => color.id === item.CodConsultorTecnico,
          )

          if (find) {
            item.identificacao = find.color
            return item
          }

          const index = this.colors.length
          let color = GRAPH_COLORS[index]

          if (!color) color = this.randomHexColor()

          item.identificacao = color
          this.colors.push({
            id: item.CodConsultorTecnico,
            color: item.identificacao,
          })

          return item
        })

        this.farmDistributions = result
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar dados',
        })
      } finally {
        this.loading.loading = false
      }
    },

    randomHexColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    },

    validateCoordinates(lat, lng) {
      return (
        isFinite(lat) &&
        Math.abs(lat) <= 90 &&
        isFinite(lng) &&
        Math.abs(lng) <= 180
      )
    },

    onUpdateFazenda(item) {
      const index = this.farmDistributions.findIndex(
        farm => farm.CodFazenda === item.CodFazenda,
      )

      this.farmDistributions[index].CodConsultorTecnico =
        item.CodConsultorTecnico

      this.farmDistributions[index].identificacao = this.colors.find(
        color => color.id === item.CodConsultorTecnico,
      ).color
    },

    visibleCoordenates(item) {
      this.farmDistributions = this.farmDistributions.map(farm => {
        if (farm.CodFazenda === item.CodFazenda) {
          farm.checked = !farm.checked
        }

        return farm
      })
    },

    filterFarmDistribution() {
      if (this.farmFilter === 'all') {
        return this.farmDistributions
      }

      if (this.farmFilter === 'active') {
        return this.farmDistributions.filter(farm => farm.ATIVO === '1')
      }

      if (this.farmFilter === 'inactive') {
        return this.farmDistributions.filter(farm => farm.ATIVO === '0')
      }
    },

    onFocus(item) {
      this.focus = item
    },

    externalLink() {
      if (this.$route.query?.userId) {
        this.externalUserId = this.$route.query.userId
      }
    },

    selectAll() {
      this.form.consultores = this.consultores.map(consultor => consultor.id)
    },
  },
}
</script>

<style>
.text-start span {
}
</style>
